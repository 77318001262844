<template>
  <div class="page">
    <div class="container head">
      <div class="title">{{mainGoods.osup_name}}<div class="subtitle">{{mainGoods.goods_name}}</div></div>
      <div class="line"></div>
      <div class="date-box">
        <div class="subtitle">使用日期</div>
        <div class="list flexcenter">
          <div
              v-for="(date, idx) in showDate"
              :key="date.d"
          >
            <div
                v-if="currentDate==date.date"
                class="item active flexcenter"
                @click="changeCurrent(date.date)">
              <div class="forf">
                <div>{{date.date_text}}</div>
                <div>￥{{date.price}}</div>
              </div>
            </div>
            <div
                v-else
                class="item flexcenter"
                @click="changeCurrent(date.date)">
              <div class="forf">
                <div>{{date.date_text}}</div>
                <div>￥{{date.price}}</div>
              </div>
            </div>
          </div>
          <div class="item flexcenter" @click="showCalendar">
            <div class="forf">
              <div style="span-align: center;text-align: center;">></div>
              <div>更多日期</div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="xuzhi flexcenter">
        <div class="left">需携带身份证</div>
        <div class="right" @click="showDetail(mainGoods.id)">购买须知 ></div>
      </div>
      <div class="line"></div>
    
      <div class="count-box">
        <div class="subtitle">购买票数</div>
        <div class="piao-box flexcenter">
          <div class="left">{{mainGoods.abbreviation}}</div>
          <div class="right flexcenter">
            <span class="total">￥{{selectedData[mainGoods['id']]['price']}}</span>
            <img @click="jianNum(mainGoods.id)" class="img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/icon/jian.png">
            <span class="count">{{selectedData[mainGoods['id']]['number']}}</span>
            <img @click="jiaNum(mainGoods.id)" class="img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/icon/add.png">
          </div>
        </div>
        <!-- <div class="desc">票种限购{{mainGoods.max_qty}}张</div> -->
        <div
            class="piao-box flexcenter"
            v-for="(item, idx) in otherGoods"
            :key="item.id"
        >
          <div class="left">{{item.abbreviation}}</div>
          <div class="right flexcenter">
            <span class="total">￥{{selectedData[item['id']]['price']}}</span>
            <img @click="jianNum(item.id)" class="img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/icon/jian.png">
            <span class="count">{{selectedData[item['id']]['number']}}</span>
            <img @click="jiaNum(item.id)" class="img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/icon/add.png">
          </div>
        </div>
      </div>
    </div>
  
    <div class="container" v-if="bindGoods.goods_id">
      <div class="count-box">
        <div class="subtitle">套餐商品</div>
        <div class="goods-list-box">
          <div class="detail-box">
            <div class="left">
              <img class="full-img" mode="widthFix" :src="bindGoods.default_img">
                {{bindGoods.sku_goods_name}}
            </div>
            <div class="right">
            
              <div class="title-box">
                <div class="title ellipsis2">{{bindGoods.goods_name}}</div>
              </div>
            
              <div class="piao-box flexcenter">
                <div class="total">￥{{bindGoods.price}}</div>
                <div class="addjian flexcenter">
                  <span class="count"><span class="tit">数量</span> x {{mainGoods.bind_goods_num}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xuzhi flexcenter">
        <div class="left">园区门票成功出票后开始安排发货</div>
      </div>
    </div>
  
    <div class="paymethod-box">
      <div class="item paymentBox" @click="goSelPayment">
        <div>支付方式</div>
        <div class="payIconBox">
          <div class="iconImg"><img :src="currentPayment.icon" class="payment-icon"></div>
          <div class="iconTxt">{{currentPayment.name}}</div>
          <icon class="iconfont iconyou"></icon>
        </div>
      </div>
    </div>
  
    <div
        class="container customer"
        v-for="(guest, idx) in adultsGuest"
        :key="guest.key"
    >
      <div class="subtitle">
        <span>成人{{idx+1}}</span>
        <span class="desc">用于入园身份证验证,确保数据正确</span>
      </div>
      <div class="form">
        <div class="item flexcenter">
          <span class="left">姓名</span>
          <input class="t_inp" placeholder="必填，请输入姓名" v-model="guest.name" data-type="adult"/>
        </div>
        <div class="item flexcenter">
          <span class="left">手机号</span>
          <input class="t_inp" placeholder="必填，请输入手机号" v-model="guest.phone" data-type="adult"/>
        </div>
        <div class="item flexcenter">
          <span class="left">身份证</span>
          <input class="t_inp" placeholder="必填，请输入身份证" v-model="guest.id_number" data-type="adult"/>
        </div>
      </div>
    </div>
    <div
        class="container customer"
        v-for="(guest, idx) in childGuest"
        :key="guest.key"
    >
      <div class="subtitle">
        <span>儿童{{idx+1}}</span>
        <span class="desc">用于入园身份证验证,确保数据正确</span>
      </div>
      <div class="form">
        <div class="item flexcenter">
          <span class="left">姓名</span>
          <input class="t_inp" placeholder="必填，请输入姓名" v-model="guest.name" />
        </div>
        <div class="item flexcenter">
          <span class="left">手机号</span>
          <input class="t_inp" placeholder="必填，请输入手机号" v-model="guest.phone" />
        </div>
        <div class="item flexcenter">
          <span class="left">身份证</span>
          <input class="t_inp" placeholder="必填，请输入身份证" v-model="guest.id_number" />
        </div>
      </div>
    </div>
  
    <div
        class="container customer"
        v-for="(guest, idx) in oldGuest"
        :key="guest.key"
    >
      <div class="subtitle">
        <span>老年人{{idx+1}}</span>
        <span class="desc">用于入园身份证验证,确保数据正确</span>
      </div>
      <div class="form">
        <div class="item flexcenter">
          <span class="left">姓名</span>
          <input class="t_inp" placeholder="必填，请输入姓名" v-model="guest.name"/>
        </div>
        <div class="item flexcenter">
          <span class="left">手机号</span>
          <input class="t_inp" placeholder="必填，请输入手机号" v-model="guest.phone"/>
        </div>
        <div class="item flexcenter">
          <span class="left">身份证</span>
          <input class="t_inp" placeholder="必填，请输入身份证" v-model="guest.id_number"/>
        </div>
      </div>
    </div>
  
    <div class="container customer">
      <div class="subtitle">
        <span>联系人</span>
        <!--<span class="desc">需填 1 位，用于入园身份证验证</span>-->
      </div>
      <div class="form">
        <div class="item flexcenter">
          <span class="left">姓名</span>
          <input class="t_inp" placeholder="必填，请输入姓名" v-model="contact_name"/>
        </div>
        <div class="item flexcenter">
          <span class="left">手机号</span>
          <input class="t_inp" placeholder="必填，请输入手机号" v-model="contact_phone"/>
        </div>
        <div class="item flexcenter">
          <span class="left">Email</span>
          <input class="t_inp" placeholder="必填，请输入Email" v-model="contact_email"/>
        </div>
        <div class="item flexcenter" v-if="bindGoods.goods_id">
          <span class="left">收货地址</span>
          <input class="t_inp" placeholder="必填，请填写收货地址" v-model="contact_address"/>
        </div>
      </div>
    </div>
  
    <div class="container"></div>
  
    <div class="bottom flexcenter">
      <div></div>
      <div class="cashier flexcenter">
        <div class="heji">合计: </div>
        <div class="price">￥{{orderAmount}}</div>
        <div class="buy" @click="createTourOrder">立即支付</div>
      </div>
    </div>
  
    <van-popup
        round
        v-model="showSelPayment"
        closeable
    >
      <div class="selPaymentListBox">
        <div
            class="paymentItem"
            @click="onSelPayment(idx)"
            v-for="(item, idx) in paymentList"
            :key="item.id"
        >
          <div v-if="currentPayment.id==item.id" class="checkbox iconfont iconxuanzhong"></div>
          <div v-else class="checkbox iconfont iconweixuanzhong"></div>
          <div class="payment">
            <div class="icon"><img :src="item.icon"></div>
            <div class="name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </van-popup>
  
    <van-calendar
        v-model="isShowCalendar"
        :formatter="formatter"
        @close="isShowCalendar=false"
        @confirm="confirmCalendar"
    />
  </div>
</template>

<script>
  import mixinOrder from 'utils/mixinOrder'
  
  export default {
    name: 'checkout',
    mixins: [mixinOrder],
    data() {
      return {
        loading:false,
        isShowCalendar: false,
        //guestList:[{name:'',id_type:'1',id_number:'',phone:''}],
        adultsGuest:[],
        childGuest:[],
        oldGuest:[],
        currentDate:'',
        selectedData:[],
        showDate:[],
        mainGoods:[],
        otherGoods:[],
        bindGoods:[],
        orderAmount:'0.00',
        contact_name:'',
        contact_phone:'',
        contact_email:'',
        contact_address:'',
        currentPayment:[], //支付方式
        paymentList:[], //可用支付方式
        showSelPayment:false, //显示支付方式选择弹框
        option_tid:'',
        formatter(day) {
          return day
        },
      }
    },
    components: {
    },
    created() {
      let tid = this.$route.query.tid
      this.opacity = tid
      this.getDetail(tid);
    },
    methods: {
      /**
       * 获取门票详情
       */
      getDetail(tid,datetime=''){
        let that = this;
        this.post(
          this.API.tourCheckout,
          {tid,date:datetime}
        ).then(response => {
          if(response.data.calendar.length<=0) {
            this.$toast('已售完')

            setTimeout(() => {
              this.$router.go(-1)
            }, 1000);
          }

          let mainGoods = response.data.goods.mainGoods;
          let otherGoods = response.data.goods.otherGoods;
          let bindGoods = response.data.goods.bindGoods;
          let calendar = response.data.calendar.calendar;
          let showDate = response.data.calendar.profile;
          let current = response.data.calendar.current;
          let selected = response.data.selected;
          let paymentList = response.data.payments;

          if(mainGoods.person_type=='1') {
            that.adultsGuest = [{name:'',id_type:'1',id_number:'',phone:'',person_type:mainGoods.person_type}]

          }

          if(mainGoods.person_type=='2') {
            that.oldGuest = [{name:'',id_type:'1',id_number:'',phone:'',person_type:mainGoods.person_type}]
          }

          if(mainGoods.person_type=='3') {
            that.childGuest=[{name:'',id_type:'1',id_number:'',phone:'',person_type:mainGoods.person_type}]

          }

          bindGoods.bind_goods_num = mainGoods.bind_goods_num;

          this.mainGoods= mainGoods
          this.otherGoods= otherGoods
          this.bindGoods=bindGoods
          this.showDate=showDate
          this.currentDate=current.date
          this.selectedData=selected
          this.paymentList=paymentList
          this.currentPayment=paymentList[0]
          this.formatter = function(day) {
            var curr_date = day.date.getDate();
            var curr_month = day.date.getMonth() + 1;
            var curr_year = day.date.getFullYear();
            String(curr_month).length < 2 ? (curr_month = "0" + curr_month): curr_month;
            String(curr_date).length < 2 ? (curr_date = "0" + curr_date): curr_date;
            var yyyyMMdd = curr_year + "-" + curr_month +"-"+ curr_date;
            //console.log(yyyyMMdd);
            if(calendar[yyyyMMdd])
            {
              day.bottomInfo = '￥'+calendar[yyyyMMdd]['price'];
            }
            else{
              day.bottomInfo = '售罄'
              day.type = 'disabled'
            }

            return day
          }
          that.calacAmount();
        })

      },
      
      /**
       * 切换时间
       */
      changeCurrent(date){
        let newSelectedData = [];
        let selectedData = this.selectedData;
        for(let key  in selectedData)
		{
          let item = selectedData[key];
          item.date = date;
          newSelectedData[key] = item;
        }
        this.currentDate=date

        let that = this;
        this.post(
          this.API.tourRefeshSelected,
          {selected:this.selectedData}
        ).then(response => {
          let selected = response.data.selected;
          that.selectedData=selected

          that.calacAmount();
        })
      },
      showCalendar() {
        console.log('点击 更多日期')
        this.isShowCalendar= true
      },
      closeCalendar() {
        this.isShowCalendar= false
      },
      formatDate(date) {
        date = new Date(date);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      confirmCalendar(event) {
        let that = this;

        let date = event.getFullYear()+"-"+(event.getMonth()+1)+"-"+event.getDate();
        let newSelectedData = [];
        let selectedData = this.selectedData;

        for(let key  in selectedData){
          let item = selectedData[key];
          item.date = date;
          newSelectedData[key] = item;
        }

        console.log(this.selectedData);
        this.post(
          this.API.tourRefeshSelected,
          {selected:this.selectedData,profile:1}
        ).then(response => {
          let selected = response.data.selected;
          let profile = response.data.calendar.profile;
          
          this.isShowCalendar= false
          this.showDate=profile
          this.selectedData=selected
          this.currentDate=response.data.calendar.current.date

          that.calacAmount();
        })

      },
      jiaNum(tid){
        let selectedData = this.selectedData;
        let id = tid;
        let number = selectedData[id]['number'];
        let price = selectedData[id]['price']
        if(number<selectedData[id]['max_qty']||selectedData[id]['max_qty']=='-1')
        {
          selectedData[id]['number'] = parseInt(selectedData[id]['number']) + 1;
          let guest = {name:'',id_type:'1',id_number:'',phone:'',person_type:selectedData[id]['person_type']}

          if(selectedData[id]['person_type']=='1')
          {
            this.adultsGuest=this.adultsGuest.concat(guest)
          }

          if(selectedData[id]['person_type']=='2')
          {
            this.oldGuest=this.oldGuest.concat(guest)
          }

          if(selectedData[id]['person_type']=='3')
          {
            this.childGuest=this.childGuest.concat(guest)
          }

          this.selectedData=selectedData

        }
        else
        {
          this.$toast('已达上限')
        }

        this.calacAmount();
      },
      jianNum(tid){
        let selectedData = this.selectedData;
        let id = tid;
        let number = selectedData[id]['number'];
        let price = selectedData[id]['price']

        if(number>selectedData[id]['min_qty'])
        {
          selectedData[id]['number'] = parseInt(selectedData[id]['number']) - 1;
          this.selectedData=selectedData

          if(selectedData[id]['person_type']=='1')
          {
            let _adultsGuest = this.adultsGuest;
            _adultsGuest.pop();
            this.adultsGuest=_adultsGuest
          }

          if(selectedData[id]['person_type']=='2')
          {
            let _oldGuest = this.oldGuest;
            _oldGuest.pop();
            this.oldGuest=_oldGuest
          }

          if(selectedData[id]['person_type']=='3')
          {
            let _childGuest = this.childGuest;
            _childGuest.pop();
            this.childGuest=_childGuest
          }
        }else{
          this.$toast('已达下限')
        }

        this.calacAmount();
      },
      calacAmount(){
        let orderAmount = 0;
        let selectedData = this.selectedData;
        let bindGoods = this.bindGoods;

        for(let key in selectedData)
        {
          orderAmount = orderAmount + selectedData[key]['price']*selectedData[key]['number']
        }

        if(bindGoods.goods_id)
        {
          orderAmount = parseFloat(orderAmount) + parseFloat(bindGoods.price);
        }

        this.orderAmount=orderAmount
      },
      /**选择支付方式 */
      goSelPayment:function(){
        console.log("sel_payment");
        this.showSelPayment=true
      },
      closeSelPayment:function(){
        this.showSelPayment=false
      },
      onSelPayment:function(idx){
        let that = this;
        console.log(idx)
        this.currentPayment=that.paymentList[idx]
        this.showSelPayment=false
      },
      showDetail(event){
        let id = event.currentTarget.dataset.id;
        this.$router.push({
          name: 'tourDetail',
          query: {
            tid: id,
          }
        })
      },
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail.value;
        let idx = dataset.idx;
        let name = dataset.name;
        let type = dataset.type;

        if(type=='adult')
        {
          let _adultGuest = this.adultsGuest;
          _adultGuest[idx][name] = value;

          _this.adultsGuest=_adultGuest
        }

        if(type=='child')
        {
          let _childGuest = this.childGuest;
          _childGuest[idx][name] = value;

          _this.childGuest=_childGuest
        }

        if(type=='old')
        {
          let _oldGuest = this.oldGuest;
          _oldGuest[idx][name] = value;

          _this.oldGuest=_oldGuest
        }
      },
      //双向绑定input数据
      bindNormalInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail;
        let name = dataset.name;

        _this.data[name] = value;
        _this.name=_this.data[name]

        console.log(_this.data[name]);
      },
      goBackPage:function() {
        this.$router.go(-1)
      },
    }
  };
</script>

<style>
	.van-calendar__selected-day {
		height: 60px;
	}
</style>
<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }
  
  .container {
    margin: 24px;
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
  }

  .subtitle{
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }

  .head .title {
    margin-top: 8px;
    font-size: 36px;
    font-weight: bold;
    color: #191919;
  }
  .head .line {
    margin: 32px 0;
    height: 2px;
    background-color: #EEEEEE;
  }
  .head .date-box .list {
    justify-content: space-between;
  }
  .head .date-box .list .item {
    width: 152px;
    height: 124px;
    border: 2px solid #E9E9E9;
    border-radius: 7px;
    color: #191919;
    font-size: 28px;
  }
  .head .date-box .list .active {
    border-color: #F8D414;
    background-color: #FFF6C6;
  }
  .head .date-box .list .disable {
    background-color: #F5F5F5;
    color: #C7C7C7;
  }
  .head .date-box .list .item .forf {
    padding-left: 12px;
  }
  .xuzhi {
    justify-content: space-between;
    margin-top: 24px;
    padding: 0 24px;
    height: 55px;
    background-color: rgba(254,163,15,0.1);
    border-radius: 8px;
    font-size: 24px;
  }
  .xuzhi .left {
    color: #F84F41;
  }
  .xuzhi .right {
    color: #FEA30F;
  }
  .head .count-box .piao-box {
    justify-content: space-between;
    font-size: 28px;
    padding:24px 0px;
    border-bottom: 2px dotted #C7C7C7;
  }
  .head .count-box .piao-box .right .total {
    margin-right: 40px;
    color: #F84F41;
  }
  .head .count-box .piao-box .right .img {
    width: 40px;
  }
  .head .count-box .piao-box .right .count {
    width: 80px;
    text-align: center;
  }
  .head .count-box .desc {
    margin-top: 14px;
    margin-bottom: 8px;
    padding-left: 16px;
    height: 55px;
    line-height: 55px;
    background-color: #F5F5F5;
    border-radius: 8px;
    font-size: 24px;
    color: #777777;
  }

  .customer .subtitle .desc {
    margin-left: 74px;
    font-size: 24px;
    font-weight: normal;
    color: #777777;
  }
  .customer .form .item {
    height: 102px;
    line-height: 102px;
    border-bottom: 2px solid #EEEEEE;
    font-size: 28px;
    color: #191919;
  }
  .customer .form .item:last-child {
    border-bottom: none;
  }
  .customer .form .item .left {
    width: 140px;
  }

  .discount {
    margin-bottom: 140px;
  }
  .discount .desc {
    justify-content: space-between;
    font-size: 28px;
    color: #191919;
  }
  .discount .desc .on {
    color: #F84F41;
  }
  .discount .desc .off {
    color: #777777;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: constant(safe-area-inset-bottom);//兼容 IOS<11.2
    bottom: env(safe-area-inset-bottom);//兼容 IOS>11.2
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .buy {
    margin-right: 30px;
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

  .graybg {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
  }

  .coupon-box {
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1034px;
    padding: 30px;
    border-radius: 20px 20px 0 0;
    background-color: #FFFFFF;
  }
  .coupon-box .title-box {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 56px;
  }
  .coupon-box .title-box .img {
    margin-right: 30px;
    width: 24px;
  }
  .coupon-box .list {
    height: 650px;
  }
  .coupon-box .list .list-inner {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .coupon-box .list .list-inner .img-box {
    position: relative;
    width: 330px;
    margin-bottom: 30px;
  }
  .coupon-box .list .list-inner .img-box .img {
    display: block;
    width: 100%;
  }
  .coupon-box .list .list-inner .img-box .chosen {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 64px;
  }
  .coupon-box .confirm {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 28px;
    margin: 0 30px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    background-color: #FCC601;
    text-align: center;
    color: #FFFFFF;
  }


  .van-calendar__bottom-info {
    color: #F84F41!important;
  }
  .van-calendar__selected-day {
    border: 2px solid #F8D414;
    border-radius: 6px;
    font-weight: bold;
    color: #191919!important;
    background-color: #FFF6C6!important;
  }
  .van-button--danger {
    background-color: #F8D414!important;
    border-color: transparent!important;
  }
  .van-calendar__weekday:first-child, .van-calendar__weekday:last-child {
    color: #F8D414!important;
  }
  .subtitle {
    display: inline-block;
    margin-left: 20px;
    font-size: 24px;
  }


  .goods-list-box {
    background-color:#FFFFFF;
    margin:0px;
  }
  .detail-box {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border-bottom:2px solid #eeeeee;
  }
  .detail-box .left {
    margin-right: 24px;
    width: 160px;
  }
  .detail-box .left .sku-box {
    display: block;
    padding:2px 10px;
    font-size:26px;
    color:#FCC601;
    border-radius: 5px;
    float: right;
    margin-top: -60px;
    margin-right: 20px;
    background-color:#191919;
  }
  .detail-box .right {
    flex: 1;
  }
  .detail-box .right .title-box {
    margin-bottom: 22px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
    height: 78px;
    overflow: hidden;
  }

  .detail-box .right .piao-box {
    justify-content: space-between;
    font-size: 28px;
  }
  .detail-box .right .piao-box .total {
    font-size: 30px;
    color: #F84F41;
  }
  .detail-box .right .piao-box .addjian .count {
    width: 160px;
    text-align: center;
  }
  .detail-box .right .piao-box .addjian .count .tit {
    font-size:24px;
    color:#cccccc;
  }

  .paymethod-box {
    margin: 24px 24px 24px 24px;
    padding: 20px 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-size: 28px;
    color: #191919;
  }
  .paymethod-box .line {
    height: 2px;
    background-color: #EEEEEE;
  }
  .paymethod-box .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .paymethod-box .red {
    font-weight: bold;
    color: #F84F41;
  }
  .paymethod-box .gray {
    color: #777777;
  }
  .paymethod-box .liuyan {
    padding:20px;
    height: 72px;
    border: 2px solid #F5F5F5;
  }
  .payment-icon {
    width: 40px;
    height: 40px;
  }

  .paymethod-box .paymentBox {
    height: 80px;
  }
  .payIconBox {
    display: flex;
    line-height: 62px;
    vertical-align: middle;
  }
  .payIconBox .iconImg {
    margin-right: 6px;
    padding-top: 10px;
  }
  .payIconBox .iconTxt {
    padding-top: 2px;
  }
  .t_inp {
	  border:none;
	  width: 300px;
	  height: 50px;
	  line-height: 50px;
  }

</style>
