let mixin = {
  methods: {
    /**
     * 创建订单
     */
    createTourOrder(){
      let that = this;
      let orderInfo = {};
      orderInfo.selected = this.selectedData;
      orderInfo.adultsGuest = this.adultsGuest;
      orderInfo.childGuest = this.childGuest;
      orderInfo.oldGuest = this.oldGuest;
      orderInfo.bindGoods = this.bindGoods;
      orderInfo.contact_name = this.contact_name;
      orderInfo.contact_phone = this.contact_phone;
      orderInfo.contact_email = this.contact_email;
      orderInfo.contact_address = this.contact_address;
      orderInfo.payment = this.currentPayment.id;
      orderInfo.channel = 7;
      orderInfo.channel_name = "云闪付";

	
      this.post(this.API.tourCreateOrder,orderInfo).then(
		response => {
			
			if(response.data.err=='1')
			{
			  this.$toast(response.data.msg)
			  return false;
			}
			else
			{
			  let trans_no = response.data.paydata.data.trans_no;
			  if(response.data.paydata.data.type=='local')
			  {
			    that.$router.push({
			      name: 'mytickets',
			      query: {
			        ostatus:"2",
			      }
			    })
			    return ;
			  }
			  else
			  {
				  console.log(response.data.paydata.data.result.tn);
				  // TODO: 需要请求后端接口获取订单号
				  upsdk.pay({
				    tn: response.data.paydata.data.result.tn,
				    success: function(res){
				  	console.log(res);
				      that.checkTourPay(trans_no)
				    },
				    fail: function(err){
						that.cancelTourPay(err)
				    }
				  });
			  }
			}
		}
	  );
    },
    checkTourPay:function(trans_no,res) {
	  let that = this;
	  this.post(this.API.checkPay,{trans_no:trans_no})
		  .then(
			response => {
				if(response.data.err=='0')
				{
					that.$toast("支付成功")
				  
					that.$router.push({
						name: 'mytickets',
						query: {
							ostatus:"2",
						}
				  }	)
				}
				else
				{
					that.$toast(response.data.msg);
				  
					that.$router.push({
						name: 'mytickets',
						query: {
						  ostatus:"2",
						}
					})
				}
		});
    },
    cancelTourPay:function(res){
		console.log(res);
      this.$toast("您取消了支付"+res.msg);
    },
  }
}

export default mixin
